@mixin clearfix() {
  &:before,
  &:after {
      content: '';
      display: table;
      clear: both;
  }
}

@mixin scale($value) {
  transform: scale($value);
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
  -o-transform: scale($value);
  -ms-transform: scale($value);
}

@mixin scale2D($value, $value2) {
  transform: scale($value, $value2);
  -webkit-transform: scale($value, $value2);
  -moz-transform: scale($value, $value2);
  -o-transform: scale($value, $value2);
  -ms-transform: scale($value, $value2);
}

@mixin translateX($left) {
  transform: translateX($left);
  -webkit-transform: translateX($left);
  -moz-transform: translateX($left);
  -o-transform: translateX($left);
  -ms-transform: translateX($left);
}

@mixin translateY($top) {
  transform: translateY($top);
  -webkit-transform: translateY($top);
  -moz-transform: translateY($top);
  -o-transform: translateY($top);
  -ms-transform: translateY($top);
}

@mixin translate2D($x, $y) {
  transform: translate($x, $y);
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
}

@mixin translate3D($x, $y, $z) {
  transform: translate3d($x, $y, $z);
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
}

@mixin rotate($deg) {
  transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
}


@mixin transitionAttr($attr, $time) {
  -o-transition: $attr $time ease-out;
  transition: $attr $time ease-out;
  -webkit-transition: $attr $time ease-out;
  -moz-transition: $attr $time ease-out;
  -ms-transition: $attr $time ease-out;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin border-radius-top($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  -moz-border-radius-topleft: $radius;
  -moz-border-radius-topright: $radius;
}

@mixin border-radius-bottom($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-radius-bottomright: $radius;
  -moz-border-radius-bottomleft: $radius;
}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
}

@mixin transform($transforms) {
  transform: $transforms;
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
}

@mixin spin {
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@mixin slideInLeft {
  -webkit-animation: slideInLeft;
  animation: slideInLeft;
}

@mixin blur($blur) {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  -moz-filter: blur($blur);
  -o-filter: blur($blur);
  -ms-filter: blur($blur);
}

@mixin opacity($op) {
  opacity: $op;
  filter: alpha(opacity=$op*100);
}

@mixin transition($time) {
  -webkit-transition: all $time ease;
  transition: all $time ease;
}

@mixin gradient() {
  background-image: linear-gradient(149deg, #ff1eec, #ff226c);
}

@mixin sub-gradient() {
  background-image: linear-gradient(149deg, #1fbad6, #17ffe3);
}

@mixin h-dark-overlay() {
  opacity: 0.85;
  background-image: linear-gradient(to bottom, #2b2b2b, #131313);
}

// Breakpoint
$xlg-width: 1920px;
$lg-width: 1200px;
$md-width: 1025px;
$sm-width: 768px;
$xxs-width: 481px;

@mixin xlg {
  @media screen and (min-width: #{$xlg-width}) {
      @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg-width}) and (max-width: #{$xlg-width - 1px}) {
      @content;
  }
}

@mixin md {
  @media (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px}) {
      @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
      @content;
  }
}

@mixin xs {
  @media screen and (max-width: #{$sm-width - 1px}) {
      @content;
  }
}

@mixin xxs {
  @media screen and (max-width: #{$xxs-width - 1px}) {
      @content;
  }
}

@mixin desktop-1 {
  @media screen and (min-width: #{$md-width - 1px}) {
      @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$md-width}) {
      @content;
  }
}

@mixin device {
  @media screen and (max-width: #{$md-width}) {
      @content;
  }
}

@mixin device-1 {
  @media screen and (max-width: #{$md-width}) {
      @content;
  }
}

@mixin tablet {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
      @content;
  }
}

@mixin tablet-1 {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 2px}) {
      @content;
  }
}

@mixin bp1024 {
  @media (min-width: #{$md-width - 1px}) and (max-width: #{$md-width - 1px}) {
      @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$sm-width}) {
      @content;
  }
}

@mixin print {
  @media print {
      @content;
  }
}

@mixin iphone5Landscape {
  @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
      @content;
  }
}

@mixin iphonePortrait {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
      @content;
  }
}

@mixin portrait {
  @media screen and (orientation: portrait) {
      @content;
  }
}

@mixin retina {
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
      @content;
  }
}

@mixin print {
  @media print {
      @content;
  }
}
@mixin lg-screen {
  @media (min-width: 1600px) {
      @content;
  }
}

@mixin md-screen {
  @media (max-width: 1600px) {
      @content;
  }
}

@mixin sm-screen {
  @media screen and (max-width: 1365px) {
      @content;
  }
}
