html {
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  overflow-x: hidden;

  @include device-1() {
    overflow-x: hidden;
  }
}

.fo4-container {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

//
// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
//
*,
*::before,
*::after {
  box-sizing: inherit;
}

/// Anchor
a {
  color: #eb9bd0;
  text-decoration: none;
  transition: color .25s ease;

  &:hover,
  &:focus {
    color: $color-primary-dark;
    text-decoration: none;
  }
}

/// Block elements
p,
pre,
figure {
  margin: 0 0 2rem 0;
  line-height: 150%;

  &:empty {
    display: none;
  }
}

blockquote,
.explanation {
  display: block;
  margin-bottom: 1em;
  margin-left: 0;
  padding-left: 1em;

  font-weight: lighter;
  font-style: normal;

  border-left: 5px solid $color-primary;

  >*:last-child {
    margin-bottom: 0;
  }
}

hr {
  border: 0;
  height: 1px;
  background: $gray-light;
}

img {
  border: 0;
}


/// Table
/// Unstyled table
table {
  margin-bottom: 1rem;

  th {
    font-weight: bold;
    text-align: left;
  }
}

/// Styled table
.table {
  width: 100%;
  max-width: 100%;

  td,
  th {
    padding: 1rem;
    border-bottom: 1px solid $gray-light;
  }

  th {
    font-weight: bold;
  }

  thead {

    td,
    th {
      border-bottom-width: 2px;
    }
  }

  tbody,
  tfoot {
    tr:last-child {

      td,
      th {
        border-bottom: 0;
      }
    }
  }

  tfoot {

    td,
    th {
      border-top: 2px solid $gray-light;
    }
  }

  caption {
    padding: 0.75rem;
    font-size: small;
    color: $gray;
  }
}

/// Lists
ul {
  @extend p;
  list-style: disc;
  margin-left: 1.4em;
  padding-left: 0;

  ul,
  ol {
    margin-bottom: 0;
  }
}

ol {
  @extend p;
  list-style: decimal;
  margin-left: 1.4em;
  padding-left: 0;

  ul,
  ol {
    margin-bottom: 0;
  }
}

.no-bullet {
  list-style: outside none none;
  margin-left: 0;
}

dl {
  @extend p;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

section {
  position: relative;
}

.b-arrow {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: -1.4rem;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2.5rem 0 2.5rem 1.4rem;
    border-color: transparent transparent transparent #009139;
  }

  &::before {
    content: "";
    position: absolute;
    left: -1.4rem;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2.5rem 1.4rem 2.5rem 0;
    border-color: transparent #009139 transparent transparent;
  }

  &.arrow-left {
    &::after {
      content: none;
    }
  }

  &.arrow-right {
    &::before {
      content: none;
    }
  }
}

.prevent-click {
  pointer-events: none;
  filter: grayscale(1);
}

.btn-polygon {
  width: px(197);
  height: px(48);
  line-height: px(48);
  display: inline-block;
  background-image: url($img+'images/btn-red.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: px(24);
  font-family: 'JusticeLeague';
  position: relative;

  // &:before {
  //   content: "";
  //   width: px(21);
  //   height: px(24);
  //   background-image: url($img+'images/btn_arr_white.png');
  //   background-size: 100% 100%;
  //   display: inline-block;
  //   margin-right: px(8);
  // }

  &:hover {
    transform: translate(0, -8px);
  }

  &.btn-black {
    background-image: url($img+'images/btn-black.png');
  }

  &.btn-blue {
    background-image: url($img+'images/btn-blue.png');
  }

  &.btn-brown {
    background-image: url($img+'images/btn-brown.png');
  }

  &.btn-green-light {
    background-image: url($img+'images/btn-green-light.png');
  }

  &.btn-green {
    background-image: url($img+'images/btn-green.png');
    color: #30013c;

    &:before {
      background-image: url($img+'images/btn_arr_black.png');
    }
  }

  &.btn-orange-2 {
    background-image: url($img+'images/btn-orange-2.png');
  }

  &.btn-orange {
    background-image: url($img+'images/btn-orange.png');
  }

  &.btn-red-black {
    background-image: url($img+'images/btn-red-black.png');
  }
}
