
.widht-pop{
    z-index: 1050;
    width:  auto;
    border:0;
    border-radius: 0;
    background-color: transparent;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
}
.content-border{
    background-color: transparent;
    border: none;
    padding: 0 px(35);
    padding-bottom: px(30);
}
.pop-title{
    font-size: px(36);
    font-weight: 300;
    color: #fff;
    text-align: center;
    display: inline-block !important;
    width: auto;
    text-align: center;
    margin-top: px(45);
    width: px(816);
    height: px(91);
    line-height: px(110);
    background-image: url($img+'images/pop_header.png');
    background-size: 100% 100%;
    text-transform: uppercase;
    // span{
    //     display: inline-block;
    //     padding: px(5) px(35);
    //     background: linear-gradient(90deg, #4010a9, #4010a9);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    // }
}
.modal {
    text-align: center;
    .close-pop {
        position: absolute;
        right: px(-15);
        top: px(-30);
        z-index: 1050;
        color: #fff;
        font-family: 'BeaufortforLOL', sans-serif;
        font-size: px(40);
        &:hover {
            opacity: 0.75;
        }
    }
    .modal-dialog {
        @extend .widht-pop;
        .modal-content {
            @extend .content-border;
        }

        .modal-block {
        }
        .pop-img-body{
            background-image: url($img+'images/pop-bg-new.png');
            background-size: 100% 100%;
            margin-top: px(-30);
            padding: 0 px(70);
            padding-top: px(50);
        }

        .modal-body {
            padding: 0;
        }

        .modal-header {
            .close {
                position: absolute;
                right: 10px;
                font-weight: 200;
                font-size: 4rem;
                top: 0px;
                color: #9b9b9b;
            }
        }

        .md-header {
           @extend .pop-title;
        }
    }

    .pop-body {
        img {
            max-height: 650px;
        }
    }
    &.show {
        display: block;

        .modal-backdrop {
            background-color: rgba(0, 0, 0, 0.6);
            opacity: 1;
        }
    }
    &.modal-img {
        text-align: center;
        .modal-dialog {
            padding: px(20) px(30);
            .modal-content {
                padding: 0;
            }
        }
    }
    // &.pop-history {
    //     .modal-dialog {
    //         .modal-block {
    //             background-image: url($img+'images/pop-bg-2.png');
    //             background-size: 100% 100%;
    //             min-height: px(500);
    //         }
    //     }
    // }
    &.pop-no-padd {
        padding-top: 40px;

        .modal-dialog {
            padding: 0;
        }

        .modal-block {
            padding: 0;

            .modal-content {
                padding: 0;
            }
        }
    }
    // &.pop-single-gift{
    //     .modal-dialog {
    //         .modal-block {
    //             background-image: url($img+'images/pop-single.png');
    //             background-size: 100% 100%;
    //         }
    //     }
    // }
    // &.pop-mutiple-gift{
    //     .modal-dialog {
    //         .modal-block {
    //             background-image: url($img+'images/pop-mutiple.png');
    //             background-size: 100% 100%;
    //         }
    //     }
    // }
    // &.pop-gift{
    //     .modal-dialog {
    //         .modal-block {
    //             background-image: url($img+'images/pop-gift.png');
    //             background-size: 100% 100%;
    //         }
    //     }
    // }
    // &.pop-history{
    //     .modal-dialog {
    //         .modal-block {
    //             background-image: url($img+'images/pop-history.png');
    //             background-size: 100% 100%;
    //         }
    //     }
    // }
    // &.pop-rule{
    //     .modal-dialog {
    //         .modal-block {
    //             background-image: url($img+'images/pop-rule.png');
    //             background-size: 100% 100%;
    //         }
    //     }
    // }
}
//custom class swall
.ex-sw-title{
    margin: 0;
}
.ex-sw-content{
    margin-top: px(-50);
    padding-top: px(80);
}
//--end
.swal2-popup.custom-modal {
    @extend .widht-pop;
    .swal2-content{
        padding: px(50) px(80);
        background-color: transparent;
        background-image: url($img+'images/pop-bg-new.png');
        background-size: 100% 100%;
        @extend .content-border;
        @extend .ex-sw-content;
        color: #66c4ff;
        font-size: px(30);
        font-weight: 100;
        margin-top: px(0);
        min-height: px(300);
    }
    .swal2-header{
        position: relative;
        z-index: 9;
        .swal2-title {
            @extend .pop-title;
            @extend .ex-sw-title;
            text-align: center;
        }
    }

    .swal2-actions {
        display: none !important;
    }

    &.has-btn {
        .swal2-actions {
            display: block !important;
            font-size: px(20);
        }
    }

    .swal2-close {
        text-indent: -9999px;
        display: inline-block;
        width: 91px;
        height: 72px;
        position: absolute;
        background-image: url('/images/close-pop.png');
        background-size: cover;
        background-repeat: no-repeat;
        right: 0;
        z-index: 2;

        &:hover {
            opacity: 0.75;
            transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
        }
    }
}

.swal2-container.swal2-shown {
    background-color: rgba(0, 14, 33, 0.8);
}