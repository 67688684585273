header {
    width: 100%;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    font-size: px(24);
    overflow: hidden;
    font-family: 'Beba', sans-serif;
    list-style: none;

    .menu {
        z-index: 3;
        padding: 0;
        margin: 0;
        @include clearfix();

        li {
            display: inline-block;
            margin: 0;
            text-align: center;
            transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
            font-style: italic;
            vertical-align: top;

            a,
            span {
                color: #fff;
            }

            &:hover {
                transform: scale(0.92);
            }

            &.btn-hd {
                margin-left: px(240);
                width: px(290);
            }

            &.btn-ls {
                margin-left: px(-45);
                width: px(280);
            }

            &.btn-gift {
                margin-left: px(-43);
                width: px(280);
            }

            &.logo {
                width: px(482);
                margin-left: px(-45);

                &:hover {
                    transform: scale(1);
                }
            }

            &.action-l-group {
                width: px(290);
                height: px(83);
                background-image: url($img+'images/m_login.png');
                background-size: 100% 100%;
                margin-left: px(-45);
                .wrap-content{
                    line-height: px(83);
                }
                .txt-user{
                    display: inline-block;
                    margin-right: px(10);
                }
            }

        }
    }

    .fc-info {
        background-image: url($img+'images/user_info.png');
        background-size: 100% 100%;
        width: px(394);
        height: px(39);
        float: right;
        z-index: 9;
        margin-right: px(276);
        color: #fff;
        padding: 0;
        margin: 0;
        list-style: none;
        font-family: 'Roboto', sans-serif;
        font-size: px(15);
        text-transform: uppercase;
        line-height: px(39);
        text-align: center;
        li{
            display: inline-block;
            padding: 0 px(13);
            position: relative;
            a{
                font-weight: 700;
                color: #7afffb;
                &:hover{
                    transform: scale(0.94);
                    opacity: 0.7;
                }
            }
            &:after{
                content: "";
                width: 1px;
                height: px(16);
                background-color: #fff;
                position: absolute;
                right: 0;
                top: px(10);
            }
            &:last-child{
                &:after{
                    content:none;
                }
            }
        }
    }
}