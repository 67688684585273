@keyframes shake {
    2% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    4% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    6% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    8% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    10% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    12% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    14% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    16% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    18% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    20% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    22% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    24% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    26% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    28% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    30% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    32% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    34% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    36% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    38% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    40% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    42% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    44% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    46% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    48% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    50% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    52% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    54% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    56% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    58% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    60% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    62% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    64% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    66% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    68% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    70% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    72% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    74% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    76% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    78% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    80% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    82% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    84% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    86% {
        transform: translate(0px, 1px) rotate(.5deg)
    }

    88% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    90% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    92% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    94% {
        transform: translate(0px, 0px) rotate(.5deg)
    }

    96% {
        transform: translate(1px, 1px) rotate(.5deg)
    }

    98% {
        transform: translate(1px, 0px) rotate(.5deg)
    }

    0%,
    100% {
        transform: translate(0, 0) rotate(0)
    }
}

@keyframes opatitle {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }
}

@keyframes drop-bell {
    0% {
        transform: translate(0, px(-200));
    }

    100% {
        transform: translate(0, px(0));
    }
}

@keyframes float-bell {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(px(-15));
    }

    100% {
        transform: translatey(0px);
    }
}

@keyframes swinging {
    0% {
        transform: rotate(3deg);
    }

    100% {
        transform: rotate(-3deg);
    }
}

@keyframes swing-tem {
    20% {
        transform: rotate3d(0, 0, 1, 6deg);
    }

    40% {
        transform: rotate3d(0, 0, 1, -3deg);
    }

    60% {
        transform: rotate3d(0, 0, 1, 3deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, -2deg);
    }

    100% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

@keyframes salaryMove {

    0%,
    50%,
    100% {
        transform: translate(0);
    }

    25% {
        transform: translate(10px, 5px);
    }

    75% {
        transform: translate(-10px, 10px);
    }
}

@keyframes rotatePer {

    0%,
    100% {
        transform: rotate(0);
    }

    50% {
        transform: translateY(-8px) rotateY(-25deg);
    }
}

@keyframes scalebigger {

    0%,
    20%,
    40%,
    60% {
        transform: scale(1);
        transform-origin: center center;
    }

    10%,
    30%,
    50% {
        transform: scale(1.1);
        transform-origin: center center;
    }
}


@keyframes glow {
    0% {}

    50% {
        filter: drop-shadow(0px 0px 15px #c20f27);
    }

    100% {}
}

@keyframes text-blink {

    0%,
    20%,
    22%,
    25%,
    28%,
    55%,
    80%,
    100% {
        text-shadow: 0 0 2px #000,
            0 0 10px #c20f27,
            0 0 5px #c20f27,
            0 0 25px #c20f27;
        opacity: 1;
        color: #fff04c;
    }

    21%,
    24%,
    56% {
        opacity: 0.2;
        text-shadow: none;
    }
}

@keyframes testAnimation {
    0% {
        transform: translate(74.796875px, 0px);
    }

    100% {
        transform: translate(191.1875px, 70.65625px);
    }
}

@keyframes chessJumping {

    0%,
    100% {
        transform: translate(0, 0);
    }

    60% {
        transform: translate(0, px(-100));
    }
}
@keyframes chessJumpingFinal {

    0%,
    100% {
        transform: translate(0, 0);
    }

    60% {
        transform: translate(0, px(-150));
    }
}
@keyframes ballBegin{
    0%{
        transform: translate(0,0);
    }
    95%{
        opacity: 1;
    }
    100%{
        transform: translate(0,px(-1000));
        opacity: 0;
    }
}
@keyframes ballUpDown{
    0%,50%,100%{
        transform: translate(0,0);
    }
    25%{
        transform: translate(0,px(-10));
    }
    75%{
        transform: translate(0,px(10));
    }
}
@keyframes specialText{
    0%{
        transform: translate(0,0) scale(0.1);
        opacity: 0;
    }
    50%{
        transform: translate(0,px(-20)) scale(1);
        opacity: 1;
    }
    100%{
        transform: translate(0,px(-40));
        opacity: 0.3;
    }
}
@keyframes playerRing{
    0%,50%,100%{
        transform: rotate(0);
    }
    25%{
        transform: rotate(2deg);
    }
    75%{
        transform: rotate(-2deg);
    }
}
@keyframes playerRingSP{
    0%,50%,100%{
        transform: rotate(0);
    }
    25%{
        transform: rotate(5deg);
    }
    75%{
        transform: rotate(-5deg);
    }
}
@keyframes ballGoLight{
    0%{
        transform: scale(0);
        opacity: 1;
    }
    90%{
        transform: scale(1.2);
        opacity: 1;
    }
}